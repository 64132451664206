import React, { useState } from 'react'
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';

import Button from 'components/v1/Button';

import { useGetInvoiceTraining } from 'api/training/query';
import { useCreateTagihan } from 'api/training/mutation';
import { useGlobalCtx } from 'contexts';

const ColumSize = {
  "no": "w-[80px] mr-6",
  "training": "w-[300px] mr-6",
  "tagihan": "w-[196px] mr-6",
  "date": "w-[196px] mr-6",
  "status": "w-[213.5px] mr-6",
  "action": "w-[50px]",
}

const CStatus = ({ status }) => {
  switch (status) {
    case "waiting": return <span className='bg-[#F6E5B6] rounded-2xl py-0.5 px-2 text-[16px]'>Belum Bayar</span>
    case "paid": return <span className='bg-[#ACE2C8] rounded-2xl px-2 py-0.5 text-[16px]'>Lunas</span>
    case "failed": return <span className='bg-[#FD4949] text-white py-0.5 rounded-2xl px-2 text-[16px]'>Gagal</span>
    default: return null
  }
}

const CHRow = ({ name, className, children }) => (
  <div className={`${ColumSize[name]} text-[14px] text-[#808285] ${className || ""}`}>
    {children}
  </div>
)

const CBRow = ({ name, className, children }) => (
  <div className={`${ColumSize[name]} text-[16px] text-[#101010] text-truncate ${className || ""}`}>
    {children}
  </div>
)

const ListTraining = () => {
  const locaiton = useLocation()
  const isTraining = locaiton.pathname === "/invoice/training"
  const isListing = locaiton.pathname === "/invoice"

  const { invoiceTrainingCounter, invoiceListingCounter } = useGlobalCtx()

  const [params, setParams] = useState({
    billing_status: "waiting"
  })

  const { data: listInvoice } = useGetInvoiceTraining({ params })

  const { mutate: mutateCreateTagihan } = useCreateTagihan()

  const handleCreateTagihan = (id) => {
    mutateCreateTagihan(id, {
      onSuccess: (response) => {
        const url = response?.data?.data?.payment_url ?? "";

        if (url) {
          window.open(url, "_blank")
        }
      },
      onError: (error) => {
        toast.error(error?.message ?? "Gagal membuat tagihan")
      }
    })
  }

  return (
    <div className='w-[100vw-80px] bg-[#D8D8D9]'>
      <div className='py-[14px] px-6 bg-white'>
        <p className='text-[22px] font-semibold leading-[35.596px] tracking-[-0.4px]'>Invoice</p>
        <div className='w-[310px] h-[0.5px] border-solid border-b-[#D8D8D9] my-0.5' />
        <p className='text-[13px] text-[#808285] leading-[35.596px] tracking-[-0.4px]'>Kelola invoice kantor yang masuk.</p>
      </div>
      <div className='p-6 bg-[#D8D8D9]'>
        <div className='rounded bg-white'>
          <div className='px-4 pt-2 rounded-t flex items-center border-b-[0.5px] border-solid border-[#D8D8D9]'>
            <Link to="/invoice">
              <div className={`cursor-pointer px-2 py-1 text-[13px] text-[#808285] flex items-center ${isListing ? "!border-b-[2px] border-solid border-[#252526]" : ""}`}>
                <span className='text-[17px] text-[#808285]'>Royalti</span>
                {!!(invoiceListingCounter > 0) && <span className={`ml-1 rounded-[2px] py-0.2 px-[3.5px] bg-[#FD4949] text-[11px] text-white flex items-center justify-center`}>{invoiceListingCounter}</span>}
              </div>
            </Link>
            <div className={`cursor-pointer px-2 py-1 text-[13px] text-[#808285] flex items-center ${isTraining ? "!border-b-[2px] border-solid border-[#252526]" : ""}`}>
              <span className='text-[17px] text-[#808285]'>Training</span>
              {!!(invoiceTrainingCounter > 0) && <span className={`ml-1 rounded-[2px] py-0.2 px-[3.5px] bg-[#FD4949] text-[11px] text-white flex items-center justify-center`}>{invoiceTrainingCounter}</span>}
            </div>
          </div>


          <div className='px-4 pt-4'>
            <div className='flex items-center bg-[#E6E7E8] p-0.5 w-fit rounded'>
              <button onClick={() => setParams({ ...params, billing_status: "waiting" })} className={`text-[14px] whitespace-nowrap  ${params.billing_status === "waiting" ? "text-[#252526] font-semibold bg-white shadow-[0px_0px_2px_0px_rgba(0,0,0,0.32)]" : "text-[#808285] bg-[#E6E7E8]"} w-[100px] flex items-center justify-center py-1 px-2 rounded`}>
                Belum Bayar
              </button>
              <button onClick={() => setParams({ ...params, billing_status: "paid" })} className={`text-[14px] whitespace-nowrap  ${params.billing_status === "paid" ? "text-[#252526] font-semibold bg-white shadow-[0px_0px_2px_0px_rgba(0,0,0,0.32)]" : "text-[#808285] bg-[#E6E7E8]"} w-[100px] flex items-center justify-center py-1 px-2 rounded`}>
                Sudah Bayar
              </button>
            </div>
          </div>


          <div className='p-4'>
            <div className='border-[0.5px] border-solid border-[#D8D8D9] rounded bg-[#FAFAFA]'>
              <div className='px-4 py-2 flex items-center border-b-[0.5px] border-solid border-[#D8D8D9]'>
                <CHRow name="no">No</CHRow>
                <CHRow name="training">Training</CHRow>
                <CHRow name="tagihan">Tagihan</CHRow>
                <CHRow name="date">Tanggal Invoice</CHRow>
                <CHRow name="status">Status Pembayaran</CHRow>
                <CHRow name="action"></CHRow>
              </div>
              {!!listInvoice?.data?.data?.training_billing && listInvoice.data.data?.training_billing?.map((invoice, idx) => {
                return (
                  <div key={`list-invoice-training-${invoice?.id}-${idx}`} className='px-4 py-[10px] flex items-center border-b-[0.5px] border-solid border-[#D8D8D9] last:border-b-0'>
                    <CBRow name="no">{idx + 1}</CBRow>
                    <CBRow name="training">{invoice?.training_batch}</CBRow>
                    <CBRow name="tagihan">{parseInt(invoice?.amount)?.toLocaleString("id-ID")}</CBRow>
                    <CBRow name="date">{dayjs(invoice?.invoice_date)?.format("DD-MM-YYYY")}</CBRow>
                    <CBRow name="status"><CStatus status={invoice?.billing_status} /></CBRow>
                    {invoice?.billing_status === "waiting" && <CBRow name="action"><Button onClick={() => handleCreateTagihan(invoice?.id)} text="Bayar" size="xs" /></CBRow>}
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ListTraining