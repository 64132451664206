import {
  BrowserRouter
} from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'


//Context
import { GlobalProvider } from "./contexts"

//Routes
import Routes from "./routes";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    },
  },
})
const App = () => {
  return (
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <GlobalProvider>
          <Routes />
        </GlobalProvider>
        <ToastContainer />
      </QueryClientProvider>
    </BrowserRouter >
  )
}

export default App;
