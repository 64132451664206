import React from 'react'
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';

import useListInvoice from "./useListInvoice"
import Table from '../table'

import { useGlobalCtx } from 'contexts';

const ListInvoice = () => {
  const locaiton = useLocation()
  const { data, status, setStatus, COLLUMNS } = useListInvoice()

  const isTraining = locaiton.pathname === "/invoice/training"
  const isListing = locaiton.pathname === "/invoice"

  const { invoiceTrainingCounter, invoiceListingCounter } = useGlobalCtx()

  return (
    <div className='w-[100vw-80px] bg-[#D8D8D9]'>
      <div className='py-[14px] px-6 bg-white'>
        <p className='text-[22px] font-semibold leading-[35.596px] tracking-[-0.4px]'>Invoice</p>
        <div className='w-[310px] h-[0.5px] border-solid border-b-[#D8D8D9] my-0.5' />
        <p className='text-[13px] text-[#808285] leading-[35.596px] tracking-[-0.4px]'>Kelola invoice kantor yang masuk.</p>
      </div>
      <div className='p-6 bg-[#D8D8D9]'>
        <div className='rounded bg-white'>
          <div className='px-4 pt-2 rounded-t flex items-center border-b-[0.5px] border-solid border-[#D8D8D9]'>
            <div to="/invoice">
              <div className={`cursor-pointer px-2 py-1 flex items-center ${isListing ? "!border-b-[2px] border-solid border-[#252526]" : ""}`}>
                <span className='text-[17px] text-[#808285]'>Royalti</span>
                {!!(invoiceListingCounter > 0) && <span className={`ml-1 rounded-[2px] py-0.2 px-[3.5px] bg-[#FD4949] text-[11px] text-white flex items-center justify-center`}>{invoiceListingCounter}</span>}
              </div>
            </div>
            <Link to="/invoice/training" className={`cursor-pointer px-2 py-1 text-[13px] text-[#808285] flex items-center ${isTraining ? "!border-b-[2px] border-solid border-[#252526]" : ""}`}>
              <span className='text-[17px] text-[#808285]'>Training</span>
              {!!(invoiceTrainingCounter > 0) && <span className={`ml-1 rounded-[2px] py-0.2 px-[3.5px] bg-[#FD4949] text-[11px] text-white flex items-center justify-center`}>{invoiceTrainingCounter}</span>}
            </Link>
          </div>



          <div className='px-4 pt-4'>
            <div className='flex items-center bg-[#E6E7E8] p-0.5 w-fit rounded'>
              <button onClick={() => setStatus("menunggu")} className={`text-[14px] whitespace-nowrap  ${status === "menunggu" ? "text-[#252526] font-semibold bg-white shadow-[0px_0px_2px_0px_rgba(0,0,0,0.32)]" : "text-[#808285] bg-[#E6E7E8]"} w-[100px] flex items-center justify-center py-1 px-2 rounded`}>
                Belum Bayar
              </button>
              <button onClick={() => setStatus("lunas")} className={`text-[14px] whitespace-nowrap  ${status === "lunas" ? "text-[#252526] font-semibold bg-white shadow-[0px_0px_2px_0px_rgba(0,0,0,0.32)]" : "text-[#808285] bg-[#E6E7E8]"} w-[100px] flex items-center justify-center py-1 px-2 rounded`}>
                Sudah Bayar
              </button>
            </div>
          </div>

          <div className='p-4'>
            <Table data={data} status={status} COLLUMNS={COLLUMNS} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ListInvoice