import React from 'react'
import { ReactComponent as LogoOne21 } from "assets/logo-one21.svg"

const ContentFooter = () => {
  return (
    <div className='py-2 px-6 w-full flex items-center justify-between bg-[#D8D8D9] border-t border-solid border-[#D8D8D9]'>
      <p className='text-[13px]'>CENTURY 21 Indonesia <span className='text-[#808285]'>&copy; 2024</span></p>
      <div className='flex items-center'>
        <span className='text-[13px] text-[#808285]'>Powered by</span>
        <LogoOne21 className='ml-1' />
      </div>
    </div>
  )
}

export default ContentFooter