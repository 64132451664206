import { useState, useEffect } from 'react'
import { api } from "utils";
import { PUBLIC_V1 } from "constant/URL";

const useGetAllArea = () => {
  const [allArea, setAllArea] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  const handleGetArea = async () => {
    setIsLoading(true)

    let isLooping = true
    let tempAllResult = []
    let page = 1

    const handleGetAllDomisili = async () => {
      const response = await api({}, true).get(`/${PUBLIC_V1}/areas?_for=areaonly&_limit=1000&page=${page}`)
      const newData = response?.data?.data ?? []

      if (newData?.length) {
        tempAllResult = [...tempAllResult, ...newData]
        page += 1
      } else {
        isLooping = false
      }
    }

    while (isLooping) {
      await handleGetAllDomisili(page)
    }

    setIsLoading(false)
    setAllArea(tempAllResult)
  }

  useEffect(() => {
    handleGetArea()
  }, [])

  return {
    data: allArea,
    isLoading,
  }
}

export default useGetAllArea