import { useQuery } from '@tanstack/react-query'
import {
  getListListingOffice,
  getListListingInactive
} from "./api"

export const useGetListListingOffice = ({ params, enabled }) => {
  return useQuery({
    queryKey: ['get-list-listing-office', params],
    queryFn: () => getListListingOffice(params),
    enabled
  })
}

export const useGetListListingInactive = ({ params, enabled }) => {
  return useQuery({
    queryKey: ['get-list-listing-inactive', params],
    queryFn: () => getListListingInactive(params),
    enabled
  })
}