import { useState } from 'react'
import { toast } from 'react-toastify';

// Components
import ModalEditCalonMa from 'pages/dashboard/training/components/modal-edit-ma';
import ModalSukses from "pages/dashboard/training/components/modal-sukses"
import ModalKonfirmasiNegative from "pages/dashboard/training/components/modal-konfirmasi-negative"
import Button from 'components/v1/Button';
import ModalDaftarTraining from "pages/dashboard/training/components/modal-daftar-training"

// Api
import { useDeleteCalonMa, useRegisterParticipant } from 'api/training/mutation';
import { useTrainingCtx } from "pages/dashboard/training/useTraining"

const CardParticipantPassed = ({
  full_name,
  registration_number,
  registration_number_expiry,
  company,
  id,
}) => {
  const {
    listAllTrainingAndBatches,
    refetchAll,
    isPaidBatchOpen
  } = useTrainingCtx()

  const { mutate: mutateDeleteCalonMa } = useDeleteCalonMa()
  const { mutate: mutateRegisterParticipant } = useRegisterParticipant()

  const [isOpenModalEditCalonMa, setIsOpenModalEditCalonMa] = useState(false)
  const [isOpenModalSuksesEditCalonMa, setIsOpenModalSuksesEditCalonMa] = useState(false)
  const [isOpenModalKonfirmasiHapusCalonMa, setIsOpenModalKonfirmasiHapusCalonMa] = useState(false)
  const [isOpenModalSuksesHapusCalonMa, setIsOpenModalSuksesHapusCalonMa] = useState(false)
  const [isOpenModalRegisterCalonMa, setIsOpenModalRegisterCalonMa] = useState(false)

  const handleOpenModalEditCalonMa = () => setIsOpenModalEditCalonMa(true)
  const handleCloseModalEditCalonMa = () => setIsOpenModalEditCalonMa(false)


  const handleOpenModalSuksesEditCalonMa = () => setIsOpenModalSuksesEditCalonMa(true)
  const handleCloseModalSuksesEditCalonMa = () => setIsOpenModalSuksesEditCalonMa(false)


  const handleOpenModalKonfirmasiHapusCalonMa = () => setIsOpenModalKonfirmasiHapusCalonMa(true)
  const handleCloseModalKonfirmasiHapusCalonMa = () => setIsOpenModalKonfirmasiHapusCalonMa(false)

  const handleOpenModalSuksesHapusCalonMa = () => setIsOpenModalSuksesHapusCalonMa(true)
  const handleCloseModalSuksesHapusCalonMa = () => setIsOpenModalSuksesHapusCalonMa(false)


  const handleOpenModalRegisterCalonMa = () => setIsOpenModalRegisterCalonMa(true)
  const handleCloseModalRegisterCalonMa = () => setIsOpenModalRegisterCalonMa(false)

  const handleDeleteCalonMa = () => {
    mutateDeleteCalonMa(id, {
      onSuccess: () => {
        handleCloseModalKonfirmasiHapusCalonMa()
        handleOpenModalSuksesHapusCalonMa()
        refetchAll()
      }
    })
  }


  const handleRegisterParticipant = (batchId) => {
    const payload = {
      id: batchId,
      data: {
        "training_batch_participant": {
          "user_id": id
        }
      }
    }
    mutateRegisterParticipant(payload, {
      onSuccess: () => {
        handleCloseModalRegisterCalonMa()
        refetchAll()
        toast.success("Berhasil mendaftarkan participant")
      },
      onError: (error) => {
        toast.error(error?.message)
      }
    })
  }


  return (
    <div className='border-[0.5px] border-solid border-[#D8D8D9] rounded bg-[#fff] mb-2 last:mb-0'>
      <div className='px-4 py-2 rounded-t'>
        <div className='flex items-center justify-between mb-1'>
          <p className='text-[13px] truncate mr-2'>{full_name}</p>
          {/* <button onClick={handleOpenModalEditCalonMa} className='cursor-pointer w-[12px] h-[12px] object-contain '>
            <IconInfo />
          </button> */}
        </div>
        {company?.name ? <p className='text-[11px] text-[#808285] mb-0.5'>{company?.name}</p> : <p className='text-[11px] text-[#808285] line-through'>Belum ada kantor</p>}
        {registration_number && <p className='text-[11px] text-[#D8D8D9]'>No. Registrasi: <span className='font-semibold text-[#252526]'>{registration_number}</span></p>}
      </div>
      <div className='px-4 py-2 rounded-b border-t-[0.5px] border-dashed border-[#D8D8D9]'>
        <p className='text-[11px] text-[#252526]'>Masa aktif hingga <span className='font-semibold text-[#252526] rounded-2xl bg-[#ACE2C8] py-0.5 px-[6px]'>{registration_number_expiry}</span></p>
      </div>
      {isPaidBatchOpen && <div className='flex justify-end px-4 py-2 rounded-b border-t-[0.5px] border-dashed border-[#D8D8D9]'>
        <Button onClick={handleOpenModalRegisterCalonMa} text="Daftarkan Training" />
      </div>}

      <ModalDaftarTraining
        isOpen={isOpenModalRegisterCalonMa}
        onRequestClose={handleCloseModalRegisterCalonMa}
        onSubmit={handleRegisterParticipant}
        list={listAllTrainingAndBatches?.data?.data ?? []}
        isPaidOnly
      />

      <ModalEditCalonMa
        isOpen={isOpenModalEditCalonMa}
        onRequestClose={handleCloseModalEditCalonMa}
        onSuccess={handleOpenModalSuksesEditCalonMa}
        onDelete={handleOpenModalKonfirmasiHapusCalonMa}
      />

      <ModalSukses
        isOpen={isOpenModalSuksesEditCalonMa}
        onRequestClose={handleCloseModalSuksesEditCalonMa}
        title="Berhasil diubah"
        info="Informasi calon marketing telah diubah."
      />

      <ModalKonfirmasiNegative
        isOpen={isOpenModalKonfirmasiHapusCalonMa}
        onRequestClose={handleCloseModalKonfirmasiHapusCalonMa}
        title="Yakin hapus calon marketing ini?"
        info={`Informasi “${full_name}” akan dihapus. Perlu mendaftarkan ulang jika ingin memasukkan kembali ke list calon marketing.`}
        onSubmit={handleDeleteCalonMa}
      />

      <ModalSukses
        isOpen={isOpenModalSuksesHapusCalonMa}
        onRequestClose={handleCloseModalSuksesHapusCalonMa}
        title="Calon marketing dihapus"
      />
    </div>
  )
}

export default CardParticipantPassed