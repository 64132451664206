import React, { useContext, useState, useMemo } from 'react'
import dayjs from 'dayjs'

//Styles
import Styles from './leads-management.module.scss'

//Assets
import { ReactComponent as Callendar } from 'assets/calendar-v2.svg'
import { ReactComponent as NextIcon } from 'assets/next.svg';
import { ReactComponent as PrevIcon } from 'assets/previous.svg';
import { ReactComponent as ClockIcon } from 'assets/clock-1.svg';
import { ReactComponent as HouseIcon } from 'assets/house-line.svg';

// Context
import { GlobalContext } from "contexts";
import {
  LeadsManagementProvider,
  useLeadsManagementState,
  useLeadsManagementMethod
} from "./useLeadsManagementContext"
import Button from 'components/v1/Button';
import { formatTanggalV1 } from "utils/formatTanggal";
import ModalChooseSchedule from "./modal-choose-schedule"

const COLWIDTH = {
  no: "w-[20px]",
  asalLeads: "w-[205px]",
  marketingName: "w-[250px]",
  customerName: "w-[250px]",
  lamaLeads: "w-[130px]",
  buttonLihat: "w-[167px]",
}

const RowHeader = () => {
  return (
    <div className={Styles['header-row']}>
      <div className={`${Styles['header-cell']} ${COLWIDTH.no}`}><span>No</span></div>
      <div className={`${Styles['header-cell']} ${COLWIDTH.asalLeads}`}><span>Asal leads</span></div>
      <div className={`${Styles['header-cell']} ${COLWIDTH.marketingName}`}><span>Marketing</span></div>
      <div className={`${Styles['header-cell']} ${COLWIDTH.customerName}`}><span>Nama customer</span></div>
      <div className={`${Styles['header-cell']} ${COLWIDTH.lamaLeads}`}><span>Lama leads</span></div>
      <div className={`${Styles['header-cell']} ${COLWIDTH.buttonLihat}`} />
    </div>
  )
}

const RowBody = ({
  no,
  asalLeads,
  marketing,
  namaCustomer,
  lamaLeads,
  handleNavigate,
  urlLeads,
  id,
  visitSchedules
}) => {
  const [openModal, setOpenModal] = useState(false);
  
  return (
    <div className={`${Styles['body-row']}`}>
      <div className={`${Styles['body-cell']} ${COLWIDTH.no}`}><span>{no}</span></div>
      <div className={`${Styles['body-cell']} ${Styles['asal-leads']} ${COLWIDTH.asalLeads}`}><span className={`${!asalLeads ? "!bg-transparent" : ""}`}>{asalLeads}</span></div>
      <div className={`${Styles['body-cell']} ${COLWIDTH.marketingName}`}><span>{marketing}</span></div>
      <div className={`${Styles['body-cell']} ${COLWIDTH.customerName}`}><span>{namaCustomer}</span></div>
      <div className={`${Styles['body-cell']} ${COLWIDTH.lamaLeads}`}>
        <span>{lamaLeads}</span>
      </div>
      <div className={`${Styles['body-cell']} ${COLWIDTH.buttonLihat}`}>
        {!!visitSchedules?.length && <Button onClick={() => setOpenModal(true)} icon={ClockIcon} size="xs" className="mr-2" text="Jadwal" />}
        <Button onClick={() => handleNavigate(urlLeads)} disabled={!urlLeads} icon={HouseIcon} size="xs" type="secondary" text="Listing" />
      </div>

      <ModalChooseSchedule visitSchedules={visitSchedules} id={id} isOpen={openModal} onClose={() => setOpenModal(false)} />
    </div>
  )
}

const Table = ({ data }) => {
  const handleNavigate = (url) => {
    window.location.href = url
  };

  return (
    <div className={`mb-[124px] ${Styles['table']}`}>
      <RowHeader />

      {!!data.length && data.map((item, index) => {
        return (
          <RowBody
            {...item}
            key={`table-${index}`}
            handleNavigate={handleNavigate}
            no={index + 1}
          />
        )
      })}
    </div>
  )
}

const LeadsManagement = () => {
  const { profile } = useContext(GlobalContext);
  const {
    page,
    totalPage,
    leadStatus,
    LEADS_STATUS,
    listLeads
  } = useLeadsManagementState()

  const {
    handleNextPage,
    handlePrevPage,
    setPage,
    handleChangeLeadStatus
  } = useLeadsManagementMethod()

  const [inputPage, setInputPage] = useState(page)

  const formatLamaLeads = (differenceInDays, currentDate, givenDate, createdAt) => {
    if (differenceInDays < 1) {
      const newGivenDate = dayjs(createdAt);
      const differenceInHours = currentDate.diff(newGivenDate, 'hour');
      return `${differenceInHours} jam lalu`;
    } else if (differenceInDays < 30) {
      return `${differenceInDays} hari lalu`;
    } else if (differenceInDays < 365) {
      const differenceInMonth = currentDate.diff(givenDate, 'month');
      return `${differenceInMonth} bulan lalu`;
    } else {
      const differenceInYear = currentDate.diff(givenDate, 'year');
      return `${differenceInYear} tahun lalu`;
    }
  }

  const LIST_LEADS = useMemo(() => {
    if (!listLeads?.length) return [];

    return listLeads.map(leads => {
      const currentDate = dayjs();
      const givenDate = formatTanggalV1(leads?.created_at);
      const differenceInDays = dayjs().diff(leads?.created_at, 'day')

      return {
        asalLeads: leads?.kind ?? "",
        marketing: leads?.user?.full_name ?? "",
        namaCustomer: leads?.name ?? "",
        lamaLeads: formatLamaLeads(differenceInDays, currentDate, givenDate, leads?.created_at),
        urlLeads: leads?.property?.links?.original_url,
        id: leads?.id,
        visitSchedules: leads?.visit_schedules,
      }
    })
  }, [listLeads])

  return (
    <div className={Styles['wrapper']}>
      <div className={Styles['header']}>
        <h1 className={Styles['title']}>Leads Management</h1>
        <h1 className={Styles['welcome']}>Selamat datang kembali, {profile?.full_name}!</h1>

        <div className={Styles['actions-wrapper']}>
          <div className={Styles['btns-wrapper']}>
            <button onClick={() => handleChangeLeadStatus(LEADS_STATUS.menunggu)} className={`${Styles['btn']} ${Styles[leadStatus === LEADS_STATUS.menunggu ? "active" : '']}`}><span>Menunggu</span></button>
            <button onClick={() => handleChangeLeadStatus(LEADS_STATUS.ditanggapi)} className={`${Styles['btn']} ${Styles[leadStatus === LEADS_STATUS.ditanggapi ? "active" : '']}`}><span>Ditanggapi</span></button>
          </div>

          <button className={Styles['btn-calendar']}>
            <Callendar />
            <span>Pilih periode</span>
          </button>
        </div>
      </div>

      <div className={`${Styles['content']}`}>
        <Table data={LIST_LEADS} />
      </div>

      {/** FOOTER */}
      <div className='fixed justify-between left-[80px] bottom-0 w-[calc(100vw-80px)] bg-white py-[40px] px-[32px] flex items-center box-shadow-1 border-[0.5px] border-solid border-white-0'>
        <div className='flex items-center'>
          <p className='text-sm text-gray-2 mr-[32px]'>Pergi ke halaman</p>
          <div className='flex items-center border-gray-2 border-[1px] border-solid rounded-[8px] px-4 py-[10px]'>
            <input min={0} onChange={(e) => setInputPage(e.target.value ?? "")} value={inputPage} max={totalPage} className='w-[32px] h-[20px] mr-2' placeholder='0' type='number' />
            <NextIcon onClick={() => setPage(inputPage)} className='w-[14px] h-[14px] object-contain cursor-pointer' />
          </div>
        </div>

        <div className='flex items-center'>
          <button onClick={handlePrevPage} className='flex items-center text-sm text-gray-0 border-[1px] border-solid rounded-md border-black-3 px-4 py-[10px]'><PrevIcon className='w-[14px] h-[14px] object-contain mr-2' /> Sebelumnya</button>
          <p className='px-[32px] text-sm'>{page} <span className='text-gray-2'>dari</span> {totalPage} <span className='text-gray-2'>halaman</span></p>
          <button onClick={handleNextPage} className='flex items-center text-sm text-gray-0 border-[1px] border-solid rounded-md border-black-3 px-4 py-[10px]'>Selanjutnya <NextIcon className='w-[14px] h-[14px] object-contain ml-2' /></button>
        </div>
      </div>
    </div>
  )
}

export default ({ ...props }) => <LeadsManagementProvider><LeadsManagement {...props} /></LeadsManagementProvider>
