import { useState, useMemo } from 'react'

// Assets
import { ReactComponent as IconInfo } from "assets/info-black.svg"

// Components
import ModalDetailParticipant from 'pages/dashboard/training/components/modal-detail-participant';
import ModalSukses from "pages/dashboard/training/components/modal-sukses"
import ModalKonfirmasiNegative from "pages/dashboard/training/components/modal-konfirmasi-negative"

// Api
import { useDeleteParticipant } from 'api/training/mutation';
import { useTrainingCtx } from "pages/dashboard/training/useTraining"

const CardParticipant = ({
  user,
  company,
  id,
  is_paid,
  billing_status,
  ...res
}) => {
  const { refetchAll } = useTrainingCtx()

  const RenderStatus = useMemo(() => {
    if (!is_paid) return "";

    if (billing_status === null) return <span className='italic text-[11px] text-[#252526] rounded-2xl bg-[#E6E7E8] py-0.5 px-[6px]'>Belum ada tagihan</span>
    if (billing_status === "waiting") return <span className='italic text-[11px] text-[#252526] rounded-2xl bg-[#F6E5B6] py-0.5 px-[6px]'>Belum bayar</span>
    if (billing_status === "paid") return <span className='italic text-[11px] text-[#252526] rounded-2xl bg-[#ACE2C8] py-0.5 px-[6px]'>Sudah bayar</span>

    return ""
  }, [is_paid, billing_status])

  const { mutate: mutateDeleteCalonMa } = useDeleteParticipant()

  const [isOpenModalEditCalonMa, setIsOpenModalEditCalonMa] = useState(false)
  const [isOpenModalSuksesEditCalonMa, setIsOpenModalSuksesEditCalonMa] = useState(false)
  const [isOpenModalKonfirmasiHapusCalonMa, setIsOpenModalKonfirmasiHapusCalonMa] = useState(false)
  const [isOpenModalSuksesHapusCalonMa, setIsOpenModalSuksesHapusCalonMa] = useState(false)

  const handleOpenModalEditCalonMa = () => setIsOpenModalEditCalonMa(true)
  const handleCloseModalEditCalonMa = () => setIsOpenModalEditCalonMa(false)

  const handleOpenModalSuksesEditCalonMa = () => setIsOpenModalSuksesEditCalonMa(true)
  const handleCloseModalSuksesEditCalonMa = () => setIsOpenModalSuksesEditCalonMa(false)


  const handleOpenModalKonfirmasiHapusCalonMa = () => setIsOpenModalKonfirmasiHapusCalonMa(true)
  const handleCloseModalKonfirmasiHapusCalonMa = () => setIsOpenModalKonfirmasiHapusCalonMa(false)

  const handleOpenModalSuksesHapusCalonMa = () => setIsOpenModalSuksesHapusCalonMa(true)
  const handleCloseModalSuksesHapusCalonMa = () => setIsOpenModalSuksesHapusCalonMa(false)

  const handleDeleteCalonMa = () => {
    const payload = {
      "participant_ids": [
        id
      ]
    }
    mutateDeleteCalonMa(payload, {
      onSuccess: () => {
        handleCloseModalKonfirmasiHapusCalonMa()
        handleOpenModalSuksesHapusCalonMa()
        refetchAll()
      }
    })
  }

  return (
    <div className='rounded bg-[#fff] mb-2 last:mb-0'>
      <div className='px-4 py-2 '>
        <div className='flex items-center justify-between mb-2'>
          <p className='text-[13px] truncate mr-2'>{user?.full_name}</p>
          <button onClick={handleOpenModalEditCalonMa} className='cursor-pointer w-[12px] h-[12px] object-contain '>
            <IconInfo />
          </button>
        </div>
        {company?.name ? <p className='text-[11px] text-[#808285]'>{company?.name}</p> : <p className='text-[11px] text-[#808285] line-through'>Belum ada kantor</p>}
        {!!user?.registration_number && <p className='text-[11px] text-[#D8D8D9]'>No. Registrasi: <span className='font-semibold text-[#252526]'>{user?.registration_number}</span></p>}
      </div>

      {is_paid && <div className='flex justify-end px-4 py-2 '>
        {RenderStatus}
      </div>}

      <ModalDetailParticipant
        isOpen={isOpenModalEditCalonMa}
        onRequestClose={handleCloseModalEditCalonMa}
        onSuccess={handleOpenModalSuksesEditCalonMa}
        onDelete={handleOpenModalKonfirmasiHapusCalonMa}
        userId={user?.id}
      />

      <ModalSukses
        isOpen={isOpenModalSuksesEditCalonMa}
        onRequestClose={handleCloseModalSuksesEditCalonMa}
        title="Berhasil diubah"
        info="Informasi calon marketing telah diubah."
      />

      <ModalKonfirmasiNegative
        isOpen={isOpenModalKonfirmasiHapusCalonMa}
        onRequestClose={handleCloseModalKonfirmasiHapusCalonMa}
        title="Yakin hapus calon marketing ini?"
        info={`Informasi “${user?.full_name}” akan dihapus. Perlu mendaftarkan ulang jika ingin memasukkan kembali ke list calon marketing.`}
        onSubmit={handleDeleteCalonMa}
      />

      <ModalSukses
        isOpen={isOpenModalSuksesHapusCalonMa}
        onRequestClose={handleCloseModalSuksesHapusCalonMa}
        title="Calon marketing dihapus"
      />
    </div>
  )
}

export default CardParticipant