import { useState, useMemo } from 'react'
import dayjs from 'dayjs';

// Components
import CModal from 'components/v1/modal';
import Button from 'components/v1/Button';

// Assets
import { ReactComponent as IconX } from "assets/x-black.svg"
import { ReactComponent as IconArrow } from "assets/arrow-down.svg"
import { ReactComponent as IconRadioActive } from "assets/radio-active-2.svg"

const ModalDaftarTraining = ({ onSubmit, list, isPaidOnly = false, ...props }) => {
  const [selectedBatch, setSelectedBatch] = useState()

  const filteredList = useMemo(() => {
    if (!list?.length) return []

    return list.filter(list => {
      if (!list?.batches) return false
      const res = list.batches.filter(b => dayjs().isBefore(b?.end_date))
      if (!res?.length) return false

      return true
    })
  }, [list])

  return (
    <CModal {...props}>
      <div className='w-[426px] rounded'>
        <div className='py-2 px-6 flex items-center justify-between border-b-[0.5px] border-solid border-[#D8D8D9]'>
          <p className='text-[17px] font-semibold leading-[27.5px] tracking-[-0.4px] mr-2'>Pilih training</p>
          <IconX onClick={props.onRequestClose} width="14px" height="14px" className='cursor-pointer' />
        </div>
        <div className='py-2 px-6 rounded-t'>
          {!!filteredList?.length && (filteredList.map((l, i) => {
            if(isPaidOnly && !l.is_paid) return null
            return (
              <div key={`list-daftar-training-${l.id}-${i}`}>
                <div className='flex items-center justify-between border-b-[0.5px] py-2 border-dashed border-[#808285]'>
                  <p className='text-[13px] text-[#808285] mr-2'>{l?.name}</p>
                  <IconArrow className='cursor-pointer rotate-180' />
                </div>
                <div className='pl-4'>
                  {!!l?.batches?.length && (l?.batches?.map((b, j) => {
                    if(dayjs().isAfter(b?.end_date)) return null
                    return (
                      <div onClick={() => setSelectedBatch(b?.id)} key={`list-daftar-batch-${b.id}-${j}`} className='flex items-center justify-between py-2 last:mb-0'>
                        <p className='text-[13px] text-[#252526] font-semibold mr-2'>{b?.start_date.replace("-", "/")}-{b?.end_date?.replace("-", "/")}</p>
                        {selectedBatch === b?.id ? <IconRadioActive className='w-4 h-4 object-contain' />
                          :
                          <div className='w-4 h-4 rounded-lg border border-solid border-[#808285]' />}
                      </div>
                    )
                  }))}
                </div>
              </div>
            )
          }))}
        </div>
        <div className='flex items-center justify-end pt-1 pb-4 px-6 border-t-[0.5px] border-solid border-[#D8D8D9] rounded-b'>
          <Button onClick={props.onRequestClose} text="Batal" className="mr-2" type="secondary" />
          <Button disabled={!selectedBatch} onClick={() => onSubmit(selectedBatch)} text="Daftarkan" />
        </div>
      </div>
    </CModal>
  )
}

export default function Wrapper({ isOpen, onAfterOpen, onRequestClose, ...props }) {
  if (!isOpen) return null;
  return <ModalDaftarTraining isOpen={isOpen} onAfterOpen={onAfterOpen} onRequestClose={onRequestClose} {...props} />
}