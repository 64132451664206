import { createContext, useContext, useState, useEffect, useMemo } from "react";
import lodash, { endsWith, result } from "lodash";
import dayjs from 'dayjs';
import relativeTime from "dayjs/plugin/relativeTime";
import "dayjs/locale/id";

import {
  //URL
  URL_E_REPORTING_COMPANY
} from "constant/URL";

import ERAction from "actions/eReportingAction";
import { 
  getMarketingAgent, 
  getTransactionReport as terapkanFilterApi, 
  getTransactionReportSummary, 
  getPendingTx,
} from "actions";
import AuthAction from "actions/authAction";

import { GlobalContext } from "contexts";

const TTCtx = createContext(null);

const TRANSACTION_STATUS = {
  "menunggu": "waiting",
  "selesai": "approved",
  "bertahap": "not_yet_paid_off",
}

export const TTProvider = ({ children }) => {
  const { profile } = useContext(GlobalContext)

  //State - Transaction
  const [transactionList, setTransactionList] = useState([])
  const [fetchingTransaction, setFetchingTransaction] = useState(true);
  const [transactionPage, setTransactionPage] = useState(1)
  const [totalTransactionPage, setTotalTransactionPage] = useState(0);
  const [auth, setAuth] = useState(null);
  const [pendingTxCounter, setPendingTxCounter] = useState(0);

  //State - Filter
  const [openModalFilter, setOpenModalFilter] = useState(false);

  //State - Download 
  const [openModalDownload, setOpenModalDownload] = useState(false);
  const [openModalKonfirmasiApproval, setOpenModalKonfirmasiApproval] = useState(false)
  const [selectedIds, setSelectedIdx] = useState([])
  const [selectedTransactionStatus, setSelectedTransactionStatus] = useState("menunggu")
  const [currentOpt, setCurrentOpt] = useState(null)

  //State - summary
  const [repportSummary, setRepportSummary] = useState({
    gross_clossed_commision: {
      amount: 0,
      unit: 0
    },
    sale_commision: {
      amount: 0,
      unit: 0
    },
    rent_commision: {
      amount: 0,
      unit: 0
    }
  })

  //Filter
  const [filterForm, setFilterForm] = useState({
    "transaction_date[from]": dayjs().subtract(1, 'month').set('date', 5).format("YYYY-MM-DD"),
    // "transaction_date[from]": dayjs().startOf('year').format("YYYY-MM-DD"),
    "transaction_date[to]": dayjs().format("YYYY-MM-DD"),
    "amount[from]": "",
    "amount[to]": "",
    "property_type_id": "",
    "status": "",
    "ma_id": "",
  })
  const [downloadForm, setDownloadForm] = useState({
    "month": "",
    "year": "",
  })

  const [agents, setAgents] = useState({
    data: [],
    fetching: true,
    error: ""
  })

  //Use memo
  const periodeTransaksi = useMemo(() => {
    dayjs.locale("id");
    dayjs.extend(relativeTime);
    const from = filterForm["transaction_date[from]"]
    const to = filterForm["transaction_date[to]"]

    if (from || to) {
      const fromDate = from ? dayjs(from).format("D MMMM YYYY") : ""
      const toDate = to ? dayjs(to).format("D MMMM YYYY") : ""

      return `${fromDate} - ${toDate}`
    }

    if (!transactionList?.length) return "";
    const currentYear = dayjs().format("YYYY")
    const startDate = dayjs(`${currentYear}-01-01`).format("D MMMM YYYY")
    const _D = transactionList?.[0]?.created_at ? transactionList?.[0]?.created_at.split(" ")[0] : "";
    const endDate = dayjs(_D).format("D MMMM YYYY")
    return `${startDate} - ${endDate}`
  }, [transactionList, filterForm["transaction_date[from]"], filterForm["transaction_date[to]"]])

  //Functions
  const getTransactionReport = async (page, opt = {}) => {
    try {
      setFetchingTransaction(true)
      let params = {
        page
      }

      const _newfilterForm = { ...filterForm, ...opt }

      if(selectedTransactionStatus === "bertahap" || selectedTransactionStatus === "menunggu") {
        delete _newfilterForm["transaction_date[from]"]
        delete _newfilterForm["transaction_date[to]"]
      }

      for (let key in _newfilterForm) {
        if (!_newfilterForm[key]) continue;
        if (typeof _newfilterForm[key] == "object") {
          params[key] = _newfilterForm[key]?.id
        } else {
          params[key] = _newfilterForm[key]
        }
      }


      let paramsString = ""

      for (let key in params) {
        if (!paramsString) {
          paramsString += `?`
        }

        if (key == "page") {
          paramsString += `${key}=${params[key]}`
        } else {
          paramsString += `&${key}=${params[key]}`
        }
      }

      paramsString += `&status=${TRANSACTION_STATUS[selectedTransactionStatus]}`

      const list = await terapkanFilterApi(paramsString)
      const totalItem = list?.headers?.["x-total"];

      setTotalTransactionPage(lodash.ceil(totalItem / 10) || 0)

      const newData = list?.data?.data ?? []

      setTransactionList(newData)
    } catch (error) {
      console.log(error)
    } finally {
      setFetchingTransaction(false)
      setOpenModalFilter(false)
    }
  }

  const mapAgents = (co_types = []) => {
    /*Mapping agent form*/
    const marketingPenjual = lodash.filter(co_types, (type) => type.kind == "co-listing")[0] ?? null;
    const marketingPembeli = lodash.filter(co_types, (type) => type.kind == "co-selling")[0] ?? null;

    //agent Penjual
    const penjualUtama = lodash.filter(marketingPenjual?.agents, (type) => type.kind == "main")[0] ?? null;
    const penjualAsisting = lodash.filter(marketingPenjual?.agents, (type) => type.kind == "assisting")[0] || null;
    const penjualDariKantorYangSama = penjualUtama?.company?.id === profile?.company?.id

    //agent Pembeli
    const pembeliUtama = lodash.filter(marketingPembeli?.agents, (type) => type.kind == "main")[0] ?? null;
    const pembeliAsisting = lodash.filter(marketingPembeli?.agents, (type) => type.kind == "assisting")[0] || null;
    const pembeliDariKantorYangSama = pembeliUtama?.company?.id === profile?.company?.id

    const result = {}

    if (penjualDariKantorYangSama) {
      result.penjualUtama = penjualUtama.agent_name || penjualUtama.user.full_name
    }

    if (pembeliDariKantorYangSama) {
      result.pembeliUtama = pembeliUtama.agent_name || pembeliUtama.user.full_name
    }

    if (penjualAsisting && penjualDariKantorYangSama) {
      result.penjualAsisting = penjualAsisting.agent_name || penjualAsisting.user.full_name
    }

    if (pembeliAsisting && pembeliDariKantorYangSama) {
      result.pembeliAsisting = pembeliAsisting.agent_name || pembeliAsisting.user.full_name
    }

    return result
  }

  const getNextTransaction = async () => {
    const page = transactionPage === 1 ? 1 : transactionPage - 1;

    setTransactionPage(page)
    await getTransactionReport(page)
  }
  const getPrevTransaction = async () => {
    const page = transactionPage === totalTransactionPage ? totalTransactionPage : transactionPage + 1;

    setTransactionPage(page)
    await getTransactionReport(page)
  }

  const getAgents = async () => {
    try {
      setAgents(val => ({ ...val, fetching: true, error: "" }))

      const list = await getMarketingAgent("?page=1&_limit=99999")

      const newData = list?.data ?? []

      setAgents(val => ({ ...val, data: newData }))
    } catch (error) {
      setAgents(val => ({ ...val, error }))
    } finally {
      setAgents(val => ({ ...val, fetching: false }))
      setFetchingTransaction(false)
    }
  }

  const terapkanFilter = async () => {
    await getTransactionReport(1)
  }
  
  const resetFilter = () => setFilterForm({
    "transaction_date[from]": "",
    "transaction_date[to]": "",
    "amount[from]": "",
    "amount[to]": "",
    "property_type_id": "",
    "status": "",
    "ma_id": ""
  })

  const resetDownload = () => setDownloadForm({
    "month": "",
    "year": "",
  })

  const btnPilihSemua = () => {
    if (!transactionList?.length) return;

    const ids = transactionList.filter(list => list?.detail?.status == "waiting").map(list => list?.id)

    setSelectedIdx(ids)
  }

  const btnBatalkanSemua = () => {
    setSelectedIdx([])
  }

  const getSummary = async (opt = {}) => {
    try {
      const __status = selectedTransactionStatus === "selesai" ? "approved" : "waiting";

      const selectedDate = `?date[from]=${opt["transaction_date[from]"]}&date[to]=${opt["transaction_date[to]"]}&status=${__status}`
      const summary = await getTransactionReportSummary(selectedDate)

      setRepportSummary(summary?.data?.data ?? {})
    } catch (error) {
      console.log(error)
    } finally {

    }
  }

  const btnSelectOne = (id) => {
    setSelectedIdx(val => {
      if (val.includes(id)) {
        const newVal = [...val]
        return newVal.filter(v => v != id)
      } else {
        return [...val, id]
      }
    })
  }

  const setujuiSemua = async () => {
    try {
      const apporve = await ERAction.approveBulkTransaction({
        "transaction_report": {
          "ids": selectedIds
        }
      });
      if (apporve?.response?.status <= 300) {
        window.location.reload()
      }
    } catch (error) {
      console.log(error)
    }
  }

  const downloadReportingCompany = async () => {
    try {
      const suffixUrl = currentOpt?.url ?? "trx.xlsx"
      const month = result(downloadForm, 'month.id', '');
      const year = result(downloadForm, 'year', '');
      const authCompany = result(auth, 'company.id', '');
      const BASE_URL = process.env.REACT_APP_BASE_URL;
      const checkUrl = endsWith(BASE_URL, '/') ? BASE_URL : BASE_URL + '/';
      const nextParam = `${checkUrl}${URL_E_REPORTING_COMPANY}/${suffixUrl}?year=${year}&month=${month}&company_id=${authCompany}`
      const outputFilename = `${Date.now()}.xls`;
      const url = nextParam;
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', outputFilename);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.log(error)
    } finally {
      setOpenModalDownload(false);
      resetDownload();
    }
  }

  const getUserProfile = async () => {
    try {

      const list = await AuthAction.getUserDetail()
      const newData = list?.data ?? {}

      setAuth(newData)
    } catch (error) {
      console.log(error)
    }
  }

  const handleGetPendingTrx = async () => {
    try{
      const result = await getPendingTx();
      setPendingTxCounter(result?.data?.data ?? 0)
    }catch(error){
      console.log(error)
    }
  }

  //Use effect
  useEffect(() => {
    getUserProfile();
    getAgents()
    handleGetPendingTrx()
  }, [])

  useEffect(() => {
    setTransactionPage(1)
    getTransactionReport(1)
    getSummary({ "transaction_date[from]": filterForm["transaction_date[from]"], "transaction_date[to]": filterForm["transaction_date[to]"] })
  }, [selectedTransactionStatus])

  return (
    <TTCtx.Provider value={{
      transactionList,
      fetchingTransaction,
      transactionPage,
      totalTransactionPage,
      periodeTransaksi,
      openModalFilter,
      openModalDownload,
      selectedIds,
      openModalKonfirmasiApproval,
      agents,
      filterForm,
      downloadForm,
      repportSummary,
      selectedTransactionStatus,
      pendingTxCounter,
      currentOpt, 
      setSelectedTransactionStatus,
      setujuiSemua,
      setTransactionList,
      setTransactionPage,
      setTotalTransactionPage,
      setOpenModalFilter,
      setOpenModalDownload,
      btnPilihSemua,
      btnBatalkanSemua,
      btnSelectOne,
      setOpenModalKonfirmasiApproval,
      setFilterForm,
      setDownloadForm,
      terapkanFilter,
      resetFilter,
      resetDownload,
      getNextTransaction,
      getPrevTransaction,
      getTransactionReport,
      getSummary,
      downloadReportingCompany,
      mapAgents,
      setCurrentOpt
    }}>
      {children}
    </TTCtx.Provider>
  )
}

//Custom hooks for the values
export const useTTCtx = () => {
  const ctx = useContext(TTCtx);

  if (!ctx) {
    throw new Error("TTCtx must be used within the TableTransaksi");
  }

  return ctx;
};