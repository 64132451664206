import { createContext, useContext, useEffect, useState, useMemo } from "react";
import _ from "lodash"
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { toast } from 'react-toastify'
import lodash from "lodash";
import { useDebounce } from "hooks/useDebounce";

//Actions
import {
  getDetailImages,
  getMarketingAgent,
  exportListing,
  archiveListing,
  getDetailListing,
  activedListing,
  deleteListing,
  deleteHotListings
} from "actions";

import { GlobalContext } from "contexts";

// api
import { useGetListListingOffice, useGetListListingInactive } from "api/list-listing/query";

//Functions
const DetailListingCtx = createContext(null);

export const DetailListingProvider = ({ children }) => {
  const navigate = useNavigate()
  const { profile, setShowLoading } = useContext(GlobalContext)
  const [isOpenModalImages, setIsOpenModalImages] = useState(false);

  //banner inactive state
  const [loading, setLoading] = useState(false)

  const [listDetail, setListDetail] = useState(null)
  const [detailImages, setDetailImages] = useState({
    data: [],
    loading: false
  })

  //Search handler
  const [search, setSearch] = useState('')
  const [openModalFilter, setOpenModalFilter] = useState(null)

  const [agents, setAgents] = useState({
    data: [],
    fetching: true,
    error: ""
  })

  const [params, setParams] = useState({
    page: 1,
    keywords: "",
    agent: null,
    filter: "active"
  })

  const mappedParams = useMemo(() => {
    let newParams = {
      page: params.page,
      keywords: params.keywords
    }

    !!params?.agent?.id && (newParams.user_id = params.agent.id)

    if (params.filter === "terjual") {
      newParams.property_type_id = 1
      newParams.is_archived = true
    } else if (params.filter === "tersewa") {
      newParams.is_archived = true
      newParams.property_type_id = 2
    } else if (params.filter === "inactive") {
      newParams.is_archived = true
    }

    return newParams
  }, [params])

  const { data: listListingOffice, isFetching: isFetchingListingOffice, refetch: refetchListListing } = useGetListListingOffice({ params: mappedParams, enabled: params.filter !== "inactive" })
  const { data: listListingInactive, isFetching: isFetchingListingInactive, refetch: refetchListInactive } = useGetListListingInactive({ params: mappedParams, enabled: params.filter === "inactive" })

  const totalPage = useMemo(() => {
    const totalItem = params.filter === "inactive" ? listListingInactive?.headers?.["x-total"] : listListingOffice?.headers?.["x-total"]

    return lodash.ceil(totalItem / 10) || 0
  }, [listListingOffice, listListingInactive, params.filter])

  const totalInactive = useMemo(() => listListingInactive?.headers?.["x-total"] ?? 0, [listListingInactive])

  const isSearchingOrFiltering = useMemo(() => {
    return !!search || !!params.agent
  }, [search, params.agent])


  const { debounceFn } = useDebounce({
    onChange: (newParams) => setParams(newParams)
  })

  const searchHandler = (e) => {
    const __value = e.target.value || "";
    setSearch(__value)
    debounceFn({ ...params, keywords: __value, page: 1 })
  }

  const getValue = (str = "") => _.get(listDetail, str)

  const _getActivedListing = async (slug) => {
    try {
      setLoading(true);
      await activedListing(slug);

      params.filter === "inactive" ? refetchListInactive() : refetchListListing();
      setListDetail(null)
      toast.success("Listing berhasil di aktifkan.");
    } catch (error) {
      setLoading(false);
      console.log(error)
    }
  }

  const _getMarkerListing = async (slug) => {
    try {
      setLoading(true);
      await archiveListing(slug);

      params.filter === "inactive" ? refetchListInactive() : refetchListListing();

      setListDetail(null)

      toast.success("Listing berhasil di arsipkan.");
    } catch (error) {
      setLoading(false);
      console.log(error)
    }
  }

  const _getDeleteListing = async (slug) => {
    try {
      setLoading(true);
      await deleteListing(slug);

      params.filter === "inactive" ? refetchListInactive() : refetchListListing();

      setListDetail(null)
      toast.success("Listing berhasil di hapuskan.");
    } catch (error) {
      setLoading(false);
      console.log(error)
    }
  }
  const _getDetailImages = async (link) => {
    try {
      setDetailImages((val) => ({ ...val, loading: true }))

      const images = await getDetailImages(link)

      setDetailImages((val) => ({ ...val, data: images.data || [] }))
    } catch (error) {
      setDetailImages((val) => ({ ...val, data: [] }))
      console.log(error)
    } finally {
      setTimeout(() => {
        setDetailImages((val) => ({ ...val, loading: false }))
      }, 500)
    }
  }

  const goToUbahListing = async () => {
    const url = "/upload-listing/" + listDetail?.slug;
    navigate(url)
  }

  const getAgents = async () => {
    try {
      setAgents(val => ({ ...val, fetching: true, error: "" }))

      const list = await getMarketingAgent("?page=1&_limit=99999")

      const newData = list?.data ?? []

      setAgents(val => ({ ...val, data: newData }))
    } catch (error) {
      setAgents(val => ({ ...val, error }))
    } finally {
      setAgents(val => ({ ...val, fetching: false }))
    }
  }

  const exportToExcel = async () => {
    try {
      const created_at_to = dayjs().format("YYYY-MM-DD")
      const created_at_from = dayjs().subtract(1, "year").format("YYYY-MM-DD")

      const query = `?company_ids[]=${profile?.company?.id}&created_at[from]=${created_at_from}&created_at[to]=${created_at_to}`
      const response = await exportListing(query)

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;

      const fileName = `Listing C21 ${profile?.company?.name} ${created_at_from} - ${created_at_to}.xlsx`
      link.setAttribute('download', fileName); // or any other extension
      document.body.appendChild(link);
      link.click();

    } catch (error) {
      console.log(error)
    } finally {

    }
  }

  const resetFilter = () => {
    setSearch("")
    setParams({
      ...params,
      page: 1,
      keywords: "",
      agent: null,
    })
  }

  const handleDeleteHotListing = async (hotListingId, callback) => {
    try {
      setShowLoading(true)
      await deleteHotListings(hotListingId)

      params.filter === "inactive" ? refetchListInactive() : refetchListListing();

      toast.success(`Hot listing dengan id ${hotListingId} berhasil di hapus.`);
    } catch (error) {
      console.log(error)
      toast.success(error);
    } finally {
      callback && callback()
      setShowLoading(false)
    }
  }

  const handleGetDetailListing = async (slug) => {
    try {
      const res = await getDetailListing(slug);

      setListDetail(res?.data ?? null)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    const _data = params.filter === "inactive" ? (listListingInactive?.data?.data ?? []) : (listListingOffice?.data?.data ?? [])
    if (!listDetail && !!_data.length) {
      setListDetail(_data[0])
    }
  }, [params.filter, listListingInactive, listListingOffice, listDetail])

  useEffect(() => {
    const links = _.get(listDetail, "links.property_images")
    links && _getDetailImages(links)
  }, [listDetail])

  useEffect(() => {
    getAgents()
  }, [])

  return (
    <DetailListingCtx.Provider value={{
      isOpenModalImages,
      listDetail,
      detailImages,
      search,
      agents,
      openModalFilter,
      agent: params.agent,
      isSearchingOrFiltering,
      company: profile?.company?.name,
      filter: params.filter,
      loading,
      totalInactive,
      params,
      setParams,
      resetFilter,
      setOpenModalFilter,
      getValue,
      setListDetail,
      setIsOpenModalImages,
      getAllMyListings: params.filter === "inactive" ? refetchListInactive : refetchListListing,
      goToUbahListing,
      searchHandler,
      exportToExcel,
      _getActivedListing,
      _getMarkerListing,
      _getDeleteListing,
      handleDeleteHotListing,
      handleGetDetailListing,
      totalPage,
      isLoading: isFetchingListingOffice || isFetchingListingInactive,
      listData: params.filter === "inactive" ? (listListingInactive?.data?.data ?? []) : (listListingOffice?.data?.data ?? [])
    }}>
      {children}
    </DetailListingCtx.Provider>
  )
}

//Custom hooks for the values
export const useDetailListingCtx = () => {
  const ctx = useContext(DetailListingCtx);

  if (!ctx) {
    throw new Error("useERState must be used within the DetailListingProvider");
  }

  return ctx;
};
