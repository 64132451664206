import { api } from "utils";
import { PUBLIC_V1 } from "constant/URL";
import { objectToQueryParams } from "utils/objectToQueryParams";

export const getListListingOffice = async (params = {}) => {
  const newParams = (!!params && typeof params === "object") ? `&${objectToQueryParams(params)}` : ""
  return api({}, true).get(`/${PUBLIC_V1}/module/property/offices?${newParams}`)
}

export const getListListingInactive = async (params = {}) => {
  const newParams = (!!params && typeof params === "object") ? `&${objectToQueryParams(params)}` : ""
  return api({}, true).get(`/${PUBLIC_V1}/properties/inactive-six-months?${newParams}`)
}
