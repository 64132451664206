import React from 'react'

// Components
import CardCalonMarketingAssociate from "./card-calon-marketing-associate"
import CardTraining from "./card-training"

// Ctx
import { useTrainingCtx } from "pages/dashboard/training/useTraining"

const ContentCard = () => {
  const { listTraining } = useTrainingCtx()
  return (
    <div className='flex flex-start w-full overflow-x-auto hide-scrollbar'>
      <CardCalonMarketingAssociate />
      {!!listTraining?.data?.data?.length && listTraining?.data?.data.map((training, idx) => <CardTraining key={`list-training-${training?.id}-${idx}`} {...training} />)}
    </div>
  )
}

export default ContentCard